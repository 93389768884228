import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"


const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <section>
        <div className="flex align-center main section">
            <article className="fade-in-up p2" style={{maxWidth: 600}}>
                <h1>Poised Meditation Tracker</h1>
                <p>In an world of high stress we need to remember that we are still simple creatures that need to control our awareness. 
                Practicing self-control and patience is essential to a successful life.</p>
                <p>Poised Meditation Tracker is a simple mobile application that helps you track your meditation sessions.</p>
                <br/>
                <a target="_blank" href='https://play.google.com/store/apps/details?id=com.poised&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                    <img className={`play-icon`} alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
                </a>
            </article>
            <figure className="phone fade-in-down shadow">
                <img src={'poised-logo-with-text.png'} className="device-logo" alt="Logo" />
            </figure>
        </div>
        <div className="flex section-half bg-blue px3">
            <article className="p2">
                <div className="flex space-between">
                    <div className="p1 text-align-center"><img src={'screenshots/main-ui.png'} alt="Learn how to meditate UI" className="sm-image-screenshot shadow" /></div>
                    <div>
                        <h2>Track Meditation Sessions</h2>
                        <p>The primary goal of Poised Mediation Tracker is to track your meditation sessions while giving you a light sound to know when to start and stop a session.</p>
                        <p>Tap the button once and a light bell will sound signifying the start of the session. Once the session is over, you will hear that sound again.</p>
                        <p>The main ui of the app will display how long it has been since you meditated.</p>
                    </div>
                </div>
            </article>
        </div>
        <div className="flex section-half bg-gray px3">
            <article className="p2">
            <div className="flex space-between">
                    <div>
                        <h2>Learn About Meditation</h2>
                        <p>When you are first starting to meditate it is helpful to have a quick guide to remind you of how to meditate.</p>
                        <p>This application includes some notes on meditation as well as a process to meditation that you can adapt.</p>
                    </div>
                    <div className="p1 text-align-center"><img src={'screenshots/how-to.png'} alt="Learn how to meditate UI" className="sm-image-screenshot shadow" /></div>
                </div>
            </article> 
        </div>
        <div className="flex section-half bg-blue px3">
            <article className="p2">
                <div className="flex space-between">
                    <div className="p1 text-align-center">
                        <img src={'screenshots/settings.png'} alt="Set the session duration." className="sm-image-screenshot shadow" />
                    </div>
                    <div>
                        <h2>Increase Session Duration</h2>
                        <p>Overtime you can increase the length of your meditation sessions. Increasing session length with make you an even better medtiator.</p>
                        <p>Remember, meditation is about the journey, not the result.</p>
                        <p>Happy Meditating!</p>
                        <br/>
                        <a target="_blank" href='https://play.google.com/store/apps/details?id=com.poised&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                            <img className={`play-icon`} alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
                        </a>
                    </div>
                </div>
            </article>
        </div>

        <footer>
        <center>Copyright 2019 | Poised Meditation Tracker | Built by <a target="_blank" className="color-primary" href="https://richardkeller.net"><strong>Richard Keller</strong></a></center>
        </footer>
    </section>

  </Layout>
)

export default IndexPage
